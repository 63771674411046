<template>
  <div class="modulo modulo_servicos" id="servicos">
    <div :class="{ container: windowWidth < 1000 }">
      <h1 class="title-servicos">
        Agende suas consultas, exames e cirurgias.
      </h1>
      <div class="medclub-creditos mt-4 mb-3">
        <b-button v-if='!usuario' @click="openLogin" class="minha-conta-mobile" style='background-color: #0e5fd6; color: white; margin-top: 15px;' icon-left="account-convert-outline">Pessoal</b-button>
        <b-button v-else-if='usuario && medempresa' @click="setTipoUsuario(false) " class="minha-conta-mobile" style='background-color: #ffba01; color: #0e5fd6; margin-top: 15px;' icon-left="account-convert-outline">Empresa</b-button>
        <b-button v-else-if='usuario && !medempresa' @click="setTipoUsuario(true) " class="minha-conta-mobile" style='background-color: #0e5fd6; color: white; margin-top: 15px;' icon-left="account-convert-outline">Pessoal</b-button>
        
        <div style="justify-content: center; display: flex; align-items: center; flex-direction: column;">
          <span>Crédito Medclub:</span>
          <span><b>{{ $root.formatPrice(saldo) }}</b></span>
        </div>
      </div>
      <div>
        <button class="button-whatsapp" @click='gotoWhatsapp("558631311881")'>
           <img  src='./../../assets/img/whatsapp.png' width="23px">
          <div class="linha-vertical"></div>
          <span>Precisando de ajuda? Acesse nosso Whatsapp!</span>
          <span class="jam jam-chevron-right"></span>
        </button>
      </div>
      <div id="carouselServicos" class="carousel slide" data-ride="carousel" data-interval="false"
        v-if="windowWidth > 768">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="carousel-item-container">
              <div class="flex justify-around" style="width: 70%;">
                <div class="carousel-item-card">
                  <router-link to="/agendar/consulta">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-medical"></span>
                      <span class="text-base servico-name">Consultas</span>
                    </a>
                  </router-link>
                </div>

                <div class="carousel-item-card">
                  <router-link to="/agendar/exames">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-activity"></span>
                      <span class="text-base servico-name">Exames</span>
                    </a>
                  </router-link>
                </div>

                <div class="carousel-item-card">
                  <router-link to="/agendar/procedimentos">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-bandage"></span>
                      <span class="text-base servico-name">Procedimentos</span>
                    </a>
                  </router-link>
                </div>

                <div class="carousel-item-card">
                  <router-link to="/agendar/cirurgia">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-cutter"></span>
                      <span class="text-base servico-name">Cirurgias</span>
                    </a>
                  </router-link>
                </div>

                <div v-if="!medempresa" class="carousel-item-card">
                  <router-link to="/agendar/odonto">
                    <a class="card_servico ">
                      <img class="jam-servicos jam" src="./../../assets/img/odonto/odonto.png" width="55px" />
                      <span class="text-base servico-name">Odonto</span>
                    </a>
                  </router-link>
                </div>

                <div class="carousel-item-card">
                  <router-link to="/pronto-socorro">
                    <a class="card_servico">
                      <img class="jam-servicos jam" src="./../../assets/img/pronto.png" width="70px" />
                      <span class="text-base servico-name">Pronto Socorro</span>
                    </a>
                  </router-link>
                </div>

               
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="carousel-item-container">
              <div class="flex justify-around" style="width: 70%;">
                <div class="carousel-item-card">
                  <router-link to="/agendar/checkup">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-task-list"></span>
                      <span class="text-base servico-name">Check-up</span>
                    </a>
                  </router-link>
                </div>
                 <div  class="carousel-item-card">
                  <router-link to="agendar/resultados">
                    <a class="card_servico">
                      <img class="jam-servicos jam" src="./../../assets/img/resultados-icon.png" width="70px" />
                      <span class="text-base text-center">Resultados<br>de Exames</span>
                    </a>
                  </router-link>
                </div>
<!-- 
                <div class="carousel-item-card">
                  <router-link to="/area-amigo-medclub">
                    <a class="card_servico ">
                      <img class="jam-servicos jam" src="./../../assets/img/amigo-medclub/amigo.png" width="70px" />
                      <span class="text-base servico-name">Amigo Medclub</span>
                    </a>
                  </router-link>
                </div> -->

                <div class="carousel-item-card">
                  <router-link to="/medclub-vantagens">
                    <a class="card_servico ">
                      <img class="jam-servicos jam" src="./../../assets/img/vantagens-icon.png" width="70px" />
                      <span class="text-base servico-name text-center">Medclub<br />Vantagens</span>
                    </a>
                  </router-link>
                </div>

                <div class="carousel-item-card">
                  <router-link to="/verpreco">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-coin"></span>
                      <span class="text-base servico-name">Ver preços</span>
                    </a>
                  </router-link>
                </div>

                <div class="carousel-item-card">
                  <a href="https://oiaqui.com/" class="card_servico " target="_blank">
                    <span class="jam-servicos jam jam-message"></span>
                    <span class="text-base servico-name text-center">Pergunte ao Doutor</span>
                  </a>
                </div>

                <div class="carousel-item-card">
                  <router-link to="/duvidas-frequentes">
                    <a class="card_servico ">
                      <span class="jam-servicos jam jam-help"></span>
                      <span class="text-base servico-name">Dúvidas?</span>
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="carousel-control-prev" href="#carouselServicos" style="width: 5%;left:8%;" role="button"
          data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Anterior</span>
        </a>
        <a class="carousel-control-next" href="#carouselServicos" style="width: 5%;right:8%;" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Próximo</span>
        </a>
      </div>
      
      <div v-else class="row row-cols-2 g-2">
        <div class="col-4" style="width: 100%">
          <router-link to="/agendar/consulta">
            <a class="card_servico">
              <span class="jam jam-medical icon-servico"></span>
              <span>Consultas</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <router-link to="/agendar/exames">
            <a class="card_servico">
              <span class="jam jam-activity"></span>
              <span>Exames</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <router-link to="/agendar/checkup">
            <a class="card_servico">
              <span class="jam jam-task-list"></span>
              <span>Check-up</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <router-link to="/agendar/procedimentos">
            <a class="card_servico">
              <span class="jam jam-bandage"></span>
              <span>Procedimentos</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <router-link to="/agendar/cirurgia">
            <a class="card_servico">
              <span class="jam jam-cutter"></span>
              <span>Cirurgias</span>
            </a>
          </router-link>
        </div>

        <div v-if="!medempresa" class="col-4">
          <router-link to="/agendar/odonto">
            <a class="card_servico">
              <img class="jam" src="./../../assets/img/odonto/odonto.png" width="55px" />
              <span>Odonto</span>
            </a>
          </router-link>
        </div>
            <div class="col-4">
                <router-link to="agendar/resultados">
                    <a class="card_servico">
                        <img class="jam-servicos jam" src="./../../assets/img/resultados-icon.png" width="70px" />
                        <span class="text-base text-center">Resultados<br>de Exames</span>
                    </a>
                </router-link>
            </div>
        <!-- <div class="col-4">
          <router-link to="/area-amigo-medclub">
            <a class="card_servico">
              <img class="jam" src="./../../assets/img/amigo-medclub/amigo.png" width="70px" />
              <span>Amigo Medclub</span>
            </a>
          </router-link>
        </div> -->

        <div class="col-4">
          <router-link to="/pronto-socorro" class="class-router-link">
            <a class="card_servico">
              <img class="jam" src="./../../assets/img/pronto.png" width="70px" />
              <span>Pronto<br/>Socorro</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <router-link to="/medclub-vantagens" class="class-router-link">
            <a class="card_servico">
              <img class="jam" src="./../../assets/img/vantagens-icon.png" width="70px" />
              <span>Medclub<br/>Vantagens</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <router-link to="/orcamento" class="class-router-link">
            <a class="card_servico">
              <span class="jam jam-coin"></span>
              <span>Ver preços</span>
            </a>
          </router-link>
        </div>

        <div class="col-4">
          <a href="https://oiaqui.com/" class="card_servico class-router-link" target="_blank" >
            <span class="jam jam-message"></span>
            <span>Pergunte <br/>ao Doutor</span>
          </a>
        </div>

        <div class="col-4">
          <router-link to="/duvidas-frequentes" class="class-router-link">
            <a class="card_servico">
              <span class="jam jam-help"></span>
              <span>Dúvidas?</span>
            </a>
          </router-link>
        </div>
        <!--
            <div class="col-12">
                <router-link to="/locaisAtendimento">
                    <a class="card_servico">
                        <img
                        class="jam"
                        src="img/icons/locais_atendimento.png"
                        width="70px"
                        />
                        <span>Locais de atendimento</span>
                    </a>
                </router-link>
            </div>
            -->
      </div>

      <div class="read_more">Role para ver mais <span class="jam jam-arrow-down"></span></div>
    </div>
  </div>
</template>
<style lang="scss">
*:not(.decorated-text) {
  text-decoration: none !important;
}
.carousel-item-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.button-whatsapp {
  display: none;
}
.medclub-creditos {
  display: none
}
.card_servico {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 653px) {
  .quebra-linha {
    display: none;
  }
} 
@media only screen and (max-width: 769px) { 
  .title-servicos {
    display: none;
  }
  .card_servico {
    span {
      font-size: 14px;
    }
  }
  .row {
    padding: 0px !important;
  }
  .col-4 {
    height: 160px !important;
    padding: 2px !important;
  }
  .modulo_servicos {
    padding: 0px !important;
  }
  .medclub-creditos {
    width: 100%;
    height: 40px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    line-height: 20px;
    border-radius: 5px;
    background-color: #fff;
  }
  .button-whatsapp {
    height: 40px !important;
    width: 100%;
    padding: 10px;
    background-color: #007F2D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    line-height: 15px;
    border-radius: 5px;
    .linha-vertical{
      height: 20px;
      color: white;
      // margin-right: 10px;
      // margin-left: 10px;
      border-left: 1px solid;
    }
    span {
      color: white;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

@media only screen and (min-width: 769px) {
  .card-desktop {
    width: 150px !important;
    height: 150px !important;
    margin-left: 10px !important;
  }

  .class-router-link {
    height: 100%;
  }

  #servicos {
    padding-top: 10px !important;
  }

  .jam-servicos {
    width: 55px !important;
    font-size: 55px !important;
  }

  .title-servicos {
    font-size: 1.7rem !important;
    font-weight: 500 !important;
    margin-left: 160px;
  }

  .modulo_servicos {
    .carousel-control-prev {
      left: 5%;

      .carousel-control-prev-icon {
        width: 50px !important;
        height: 50px !important;
      }
    }

    .carousel-control-next {
      right: 3%;

      .carousel-control-next-icon {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  .router-link a {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 996px) {
  .card-desktop {
    width: 112px !important;
    height: 112px !important;
    margin-left: 10px !important;
  }

  .carousel-item-card {
    width: 126px;
  }

  .servico-name {
    font-size: 0.8rem !important;
  }

  .card-desktop img {
    width: 38px !important;
  }

  .card-desktop .jam {
    font-size: 38px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-item-card {
    width: 120px;
  }

  .servico-name {
    font-size: 0.8rem !important;
  }

  .card-desktop img {
    width: 38px !important;
  }

  .card-desktop .jam {
    font-size: 38px !important;
  }
}

@media only screen and (min-width: 1240px) {
  .carousel-item-card {
    width: 150px;
  }
}

@media only screen and (min-width: 1440px) {
  .carousel-item-card {
    width: 164px;
  }
}
</style>
<script>
export default {
  props: ['saldo'],
  created() {
    this.usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
    this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      txt: "",
      medempresa: null,
      usuario: null,

    };
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.txt = `it changed to ${ newWidth } from ${ oldWidth }`;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    openLogin() {
      this.$parent.$parent.$parent.dialog.login = true
    },
    setTipoUsuario(valor) {
        let usuario = JSON.parse(localStorage.getItem("MEDCLUBSITE_USER"));
        if (valor && usuario.ie_associado_med_empresa)
            localStorage.setItem('MEDCLUB_TYPE_USER', usuario.ie_associado_med_empresa)
        if(!valor)
            localStorage.setItem('MEDCLUB_TYPE_USER', valor)
        if(!usuario.ie_associado_med_empresa && valor)
            this.alertMedclubEmpresa()
        else 
            this.$router.go();
            
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    gotoWhatsapp(number) {
      var url = 'https://api.whatsapp.com/send?phone=' + number
      window.open(url, '_blank');
    }
  },
};
</script>
