<template>
    <div style="background-color: #f4f7fe" class="desktop">
        <div id="carouselBanner" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active" data-interval="80000">
                    <img src="./../../assets/img/banner/med_empresa.png">
                </div>
                <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner7.png">
                </div>
                <!-- <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner1.png" usemap="#image1-map">
                    <map name="image1-map">
                        <area target="" alt="cadastre_se" title="Cadastro Grátis" href="https://medclub.com.br/cadastrar" coords="0,-2,1900,509" shape="rect">
                    </map>
                </div> -->
                <!--<div class="carousel-item">
                    <img src="./../../assets/img/banner/banner2.png" usemap="#image2-map">
                    <map name="image2-map">
                        <area alt="pronto-socorro" title="pronto-socorro" href="https://medclub.com.br/pronto-socorro" coords="0,-2,1900,509" shape="rect">
                    </map>
                    <map name="image2-map">-->
<!--                        <area target="" alt="app_store" title="app_store" href="https://apps.apple.com/br/app/medclub/id1508392465" coords="682,319,848,366" shape="rect">-->
<!--                        <area target="" alt="play_store" title="play_store" href="https://play.google.com/store/apps/details?id=com.medclub" coords="872,319,1033,369" shape="rect">-->
<!--                    </map>-->
                <!--</div> -->
                <!-- <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner3.png">
                </div> -->
                <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner9.png">

                </div>
                <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner4.png" usemap="#image4-map">
                    <map name="image4-map">
                        <area alt="odonto" title="odonto" href="https://medclub.com.br/agendar/odonto" coords="0,-2,1900,509" shape="rect">
                    </map>
                </div>
                <!-- <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner5.png"> -->

<!--                    <map name="image4-map">-->
<!--                        <area alt="odonto" title="odonto" href="https://medclub.com.br/agendar/odonto" coords="0,-2,1900,509" shape="rect">-->
                   <!-- </map>
                </div> -->
                <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner11.jpeg" usemap="#image5-map">
                    <map name="image5-map">
                        <area alt="odonto" title="exames" target="_blank" href="http://dexlab.ddns.net:82/" coords="0,-2,1900,509" shape="rect">
                    </map>
                </div>
                <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner6.png">

                </div>
                <div class="carousel-item">
                    <img src="./../../assets/img/banner/banner10.jpeg" usemap="#image6-map">
                    <map name="image6-map">
                        <area alt="odonto" title="imagem" target="_blank" href="http://resultado.medclub.com.br/" coords="0,-2,1900,509" shape="rect">
                    </map>
                </div>
                
            </div>
            <a class="carousel-control-prev" href="#carouselBanner" role="button" data-slide="prev" >
                <span class="carousel-control-prev-icon banner-controls"  aria-hidden="true" style="margin-right: 100px;"></span>
                <span class="sr-only">Anterior</span>
            </a>
            <a class="carousel-control-next" href="#carouselBanner" role="button" data-slide="next">
                <span class="carousel-control-next-icon banner-controls" aria-hidden="true" style="margin-left: 100px;"></span>
                <span class="sr-only">Próximo</span>
            </a>
        </div>
    </div>
</template>
<style>
    @media (max-width: 768px) {
        .desktop {
            display: none;
        }
    }

    .banner-controls {
        width: 70px !important;
        height: 70px !important;
        filter: invert(10%)
    }

    #carouselBanner .carousel-item img {
        width: 100%;
    }
</style>
<script>
export default {
    data(){
        return {
            carousels: [
                { text: 'Slide 1', color: 'primary' },
                { text: 'Slide 2', color: 'info' },
                { text: 'Slide 3', color: 'success' },
                { text: 'Slide 4', color: 'warning' },
                { text: 'Slide 5', color: 'danger' }
            ]
        }
    }
}
</script>