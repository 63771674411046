<template>
    <div class="modulo modulo_pergunte_ao_doutor pergunte_ao_doutor_container mt-10 sm:mt-0" id="pergunte_ao_doutor">
        <div class="container" style="margin-top: -50px">
            <h1 class="text-center">Pergunte ao Doutor</h1>
        </div>

        <div class="flex justify-center">
            <div class="row ">
                <div id="carouselPergunteAoDoutor" class="carousel slide" data-ride="carousel">

                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" :data-slide-to="index" v-for="(question, index) in questions" :key="question.id" :class="{active: index === 1}"></li>
                </ol>

                <div class="carousel-inner ">
                    <div v-for="(question, index) in questions" :key="question.id" class="carousel-item" :class="{active: index === 1}">
                        <div class="conteudo_pergunte_doutor lg:mx-10">
                            <div class="flex flex-col lg:flex-row">
                                <div class="">
                                    <div class="img_pergunte_doutor"></div>
                                </div>
                                
                                <div class="mx-10">
                                    <div class="">
                                        {{question.pergunta}}
                                    </div>
                                    <div class="mt-2">
                                        <a :href="`https://oiaqui.com/${question.url}`" target="_blank" class="mt-2">VER RESPOSTA PARA ESSA PERGUNTA</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a class="carousel-control-prev" href="#carouselPergunteAoDoutor" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Anterior</span>
                </a>
                <a class="carousel-control-next" href="#carouselPergunteAoDoutor" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Próximo</span>
                </a>
                </div>
            </div>
        </div>

        <div class="flex justify-center">
            <a href="https://oiaqui.com/" class="btn btn-primary btn_custom" target="_blank">
            <div class="text-uppercase">Mais perguntas</div>
            <span>Faça e/ou veja mais perguntas</span>
            </a>
        </div>
    </div>
</template>
<style>
.pergunte_ao_doutor_container {
    min-height: 600px;
}
</style>
<script>
import Noticias from '../../middleware/controllers/Noticias';
import configs from '../../middleware/configs';
import NovaAPI from '../../middleware/controllers/NovaAPI.js'

export default {
    data() {
        return {
            questions: []
        }
    },
    created() {
        NovaAPI.authenticateApp(configs.novaAPI.applicationId, configs.novaAPI.applicationToken)
        .then(response => {
            this.getQuestions(response);
        })
    },
    methods: {
        getQuestions(apiToken) {
            Noticias.getQuestions(apiToken)
            .then(response => this.questions = response)
        },
    }
}
</script>