<template>
    <div class="modulo modulo_como_funciona como_funciona_container" id="como_funciona">
        <div class="container flex items-center justify-center flex-col">
            <div class="mt-10"><h1>Como Funciona?</h1></div>

            <div id="carouselExampleControls" class="carousel slide como-funciona-carousel" data-ride="carousel">
<!--
                <ol class="carousel-indicators" v-if="windowWidth > 1280">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>

                <ol class="carousel-indicators" v-else-if="windowWidth > 768">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                </ol>

                <ol class="carousel-indicators" v-else>
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                </ol>
-->
                <!-- Carrosel no desktop -->
                <div class="carousel-inner" v-if="windowWidth > 1280">
                    <div class="carousel-item active">
                        <div class="row m-32">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/quem-somos.png" class="img-card">
                                    <h3>Quem Somos</h3>
                                    <p>
                                    Plataforma digital para oferecer saúde de qualidade a preços acessíveis.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/mensalidade.png" class="img-card">
                                    <h3>Sem mensalidade</h3>
                                    <p>
                                    Não cobramos taxa de adesão, mensalidade ou anuidade.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/valores.png" class="img-card">
                                    <h3>Valores reduzidos</h3>
                                    <p>
                                    Agende consultas, exames, odontologia com valores reduzidos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-32">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/facilidade.png" class="img-card">
                                    <h3>Facilidade no agendamento</h3>
                                    <p>
                                    Agende e pague seus serviços pelo site, aplicativo, totem e guichês do medclub. 
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/voce.png" class="img-card">
                                    <h3>Para você, familiares e amigos </h3>
                                    <p>
                                    Cadastro grátis!
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/locais.png" class="img-card">
                                    <h3>Locais de atendimento</h3>
                                    <p>
                                    Tenha acesso as melhores clínicas e hospitais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-32">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/cirurgias.png" class="img-card">
                                    <h3>Cirurgia</h3>
                                    <p>
                                    Ofertamos cirurgias com valores diferenciados, acompanhamos você durante todo o processo.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/vantagens.png" class="img-card">
                                    <h3>Clube de Vantagens</h3>
                                    <p>
                                    Descontos exclusivos em  locais parceiros.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/atendimento.png" class="img-card">
                                    <h3>Atendimento médico 24 horas</h3>
                                    <p>
                                    Com o medclub você conta com prontos socorros 24 horas caso apareça alguma emergência.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Carrosel no tablet -->
                <div class="carousel-inner" v-else-if="windowWidth > 768">
                    <div class="carousel-item active">
                        <div class="row m-32" >
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/quem-somos.png" class="img-card">
                                    <h3>Quem Somos</h3>
                                    <p>
                                    Plataforma digital para oferecer saúde de qualidade a preços acessíveis.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/mensalidade.png" class="img-card">
                                    <h3>Sem mensalidade</h3>
                                    <p>
                                    Não cobramos taxa de adesão, mensalidade ou anuidade.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-32">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/valores.png" class="img-card">
                                    <h3>Valores reduzidos</h3>
                                    <p>
                                    Agende consultas, exames, odontologia com valores reduzidos.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/facilidade.png" class="img-card">
                                    <h3>Facilidade no agendamento</h3>
                                    <p>
                                    Agende e pague seus serviços pelo site, aplicativo, totem e guichês do medclub. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-32">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/voce.png" class="img-card">
                                    <h3>Para você, familiares e amigos </h3>
                                    <p>
                                    Cadastro grátis!
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/locais.png" class="img-card">
                                    <h3>Locais de atendimento</h3>
                                    <p>
                                    Tenha acesso as melhores clínicas e hospitais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-32">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/cirurgias.png" class="img-card">
                                    <h3>Cirurgia</h3>
                                    <p>
                                    Ofertamos cirurgias com valores diferenciados, acompanhamos você durante todo o processo.
                                    </p>
                                </div>
                            </div>

                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/vantagens.png" class="img-card">
                                    <h3>Clube de Vantagens</h3>
                                    <p>
                                    Descontos exclusivos em  locais parceiros.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-32">
                            <div class="col-6">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/atendimento.png" class="img-card">
                                    <h3>Atendimento médico 24 horas</h3>
                                    <p>
                                    Com o medclub você conta com prontos socorros 24 horas caso apareça alguma emergência.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Carrosel no mobile -->
                <div class="carousel-inner" v-else>
                    <div class="carousel-item active">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/quem-somos.png" class="img-card">
                                    <h3>Quem Somos</h3>
                                    <p>
                                    Plataforma digital para oferecer saúde de qualidade a preços acessíveis.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">    
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/mensalidade.png" class="img-card">
                                    <h3>Sem mensalidade</h3>
                                    <p>
                                    Não cobramos taxa de adesão, mensalidade ou anuidade.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/valores.png" class="img-card">
                                    <h3>Valores reduzidos</h3>
                                    <p>
                                    Agende consultas, exames, odontologia com valores reduzidos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/facilidade.png" class="img-card">
                                    <h3>Facilidade no agendamento</h3>
                                    <p>
                                    Agende e pague seus serviços pelo site, aplicativo, totem e guichês do medclub. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/voce.png" class="img-card">
                                    <h3>Para você, familiares e amigos </h3>
                                    <p>
                                    Cadastro grátis!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/locais.png" class="img-card">
                                    <h3>Locais de atendimento</h3>
                                    <p>
                                    Tenha acesso as melhores clínicas e hospitais.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/cirurgias.png" class="img-card">
                                    <h3>Cirurgia</h3>
                                    <p>
                                    Ofertamos cirurgias com valores diferenciados, acompanhamos você durante todo o processo.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/vantagens.png" class="img-card">
                                    <h3>Clube de Vantagens</h3>
                                    <p>
                                    Descontos exclusivos em  locais parceiros.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="row m-2">
                            <div class="col">
                                <div class="card_como_funciona">
                                    <img src="@/assets/img/como-funciona/atendimento.png" class="img-card">
                                    <h3>Atendimento médico 24 horas</h3>
                                    <p>
                                    Com o medclub você conta com prontos socorros 24 horas caso apareça alguma emergência.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Anterior</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Próximo</span>
                </a>
            </div>
        </div>
    </div>
</template>
<style>
    .img-card {
        width: 100px;
        margin: 30px auto;
    }

    .carousel-indicators > li {
        background-color: #1662ce;
    }

    h3 {
         margin-bottom: 10px;
    }
 
    .como_funciona_container {
        height: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .como-funciona-carousel {
        margin-top: -10px;
    }
</style>
<script>
    export default {
        data() {
            return {
                windowWidth: window.innerWidth,
                txt: ''
            }
        },

        watch: {
            windowWidth(newWidth, oldWidth) {
                this.txt = `it changed to ${newWidth} from ${oldWidth}`;
            }
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        methods: {  
            onResize() {
                this.windowWidth = window.innerWidth
            }
        }
    }
</script>